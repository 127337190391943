import Vue from 'vue'
import { AclInstaller, AclCreate, AclRule } from 'vue-acl'
import router from '@/router'
import moduleAuthGetters from '../store/auth/moduleAuthGetters'

Vue.use(AclInstaller)

const initialRole = 'IS_AUTHENTICATED_ANONYMOUSLY'

export default new AclCreate({
  initial  : initialRole,
  notfound: { name: 'page-not-authorized' },
  router,
  acceptLocalRules : true,
  globalRules: {
    superadmin  : new AclRule('ROLE_SUPER_ADMIN').or('ROLE_ADMIN').generate(),
    admin       : new AclRule('ROLE_ADMIN').generate(),
    user        : new AclRule('ROLE_USER').or('ROLE_ADMIN').generate(),
    guest       : new AclRule('IS_AUTHENTICATED_ANONYMOUSLY').or('ROLE_USER').generate()
  },
  middleware: async acl => {
    const userInfo = moduleAuthGetters.authUser()
    if (userInfo && userInfo.roles) {
      acl.change(Object.values(userInfo.roles), { name: 'page-not-authorized' })
    } else {
      acl.change(initialRole, { name: 'home' })
    }
  }
})
