// English
import administrationEn from './en/administration.json'
import homeEn from './en/home.json'
import loginEn from './en/login.json'
import navbarEn from './en/navbar.json'
import partnerFormEn from './en/partnerform.json'

export default {
  en: {
    ...administrationEn,
    ...homeEn,
    ...loginEn,
    ...navbarEn,
    ...partnerFormEn
  }
}
