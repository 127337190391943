/*=========================================================================================
  File Name: main.js
  Description: main vue(js) file
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import Vue from 'vue'
import App from './App.vue'
import VueMeta from 'vue-meta'
// Vue Router
import router from './router'
Vue.use(VueMeta)

// Vuesax Component Framework
import Vuesax from 'vuesax'
import 'material-icons/iconfont/material-icons.css' //Material Icons
import 'vuesax/dist/vuesax.css' // Vuesax
Vue.use(Vuesax)


// axios
import axios from './axios.js'
Vue.prototype.$http = axios

// API Calls
import './http/requests'

// mock
// import './fake-db/index.js'

// ACL
import acl from './acl/acl'


// Theme Configurations
import '../themeConfig.js'


// Globally Registered Components
import './globalComponents.js'


// Styles: SCSS
import './assets/scss/main.scss'


// Tailwind
import '@/assets/css/main.css'


// Vuex Store
import store from './store/store'


// i18n
import i18n from './i18n/i18n'

// Filters
import './filters/filters.js'


// VeeValidate
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'
// import veeValidateFr from 'vee-validate/dist/locale/fr.json'

Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule])
})

extend('url', {
  validate (value) {
    if (value) {
      return /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&/=]*)/.test(value)
    }

    return false
  },
  message: 'This value must be a valid URL with \'http\''
})

extend('password', {
  params: ['target'],
  validate (value, { target }) {
    return (value === target ? true : i18n.t('administration.userform.form.input.password.error'))
  }
})

// localize('fr', veeValidateFr)

Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)


// Vuejs - Vue wrapper for hammerjs
import { VueHammer } from 'vue2-hammer'
Vue.use(VueHammer)


// PrismJS
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'


// Feather font icon
require('./assets/css/iconfont.css')


// Vue select css
// Note: In latest version you have to add it separately
import 'vue-select/dist/vue-select.css'


Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  acl,
  render: h => h(App)
}).$mount('#app')
