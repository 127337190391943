
import jwt from '../../http/requests/auth/jwt/index.js'

import router from '@/router'
import axios from '@/http/axios'

const jwtDecode = require('jwt-decode')

export default {
  updateUsername ({ commit }, payload) {
    payload.user.updateProfile({
      displayName: payload.displayName
    }).then(() => {

      // If username update is success
      // update in localstorage
      const newUserData = Object.assign({}, payload.user.providerData[0])
      newUserData.displayName = payload.displayName
      commit('UPDATE_USER_INFO', newUserData, {root: true})

      // If reload is required to get fresh data after update
      // Reload current page
      if (payload.isReloadRequired) {
        router.push(router.currentRoute.query.to || { path: '/' })
      }
    }).catch((err) => {
      payload.notify({
        time: 8800,
        title: 'Error',
        text: err.message,
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'danger'
      })
    })
  },


  // JWT
  loginJWT ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      jwt.login(payload.userDetails.username, payload.userDetails.password)
        .then(response => {
          if (response.data.token) {
            const userData = jwtDecode(response.data.token)
            userData.user.roles = userData.roles

            localStorage.setItem('accessToken', response.data.token)

            if (payload.checkbox_remember_me) {
              localStorage.setItem('refreshToken', response.data.refresh_token)
            }

            commit('UPDATE_USER_INFO', userData.user, {root: true})
            commit('SET_BEARER', response.data.token)

            router.push(router.currentRoute.meta.from || {'name': 'home'})

            resolve(response)
          } else {
            reject({message: 'login.form.failed'})
          }

        })
        .catch(error => { reject(error) })
    })
  },
  resetPasswordMailJWT (_commit, username) {
    return new Promise((resolve, reject) => {
      axios.get(`/resetting/request/${username}`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  decodeJWT ({ commit }, token) {
    const userData = jwtDecode(token)
    commit('UPDATE_USER_INFO', userData.user, {root: true})
  },

  registerUserJWT ({ commit }, payload) {

    const { displayName, email, password, confirmPassword } = payload.userDetails

    return new Promise((resolve, reject) => {

      // Check confirm password
      if (password !== confirmPassword) {
        reject({message: 'Password doesn\'t match. Please try again.'})
      }

      jwt.registerUser(displayName, email, password)
        .then(response => {
          // Redirect User
          router.push(router.currentRoute.query.to || { path: '/' })

          // Update data in localStorage
          localStorage.setItem('accessToken', response.data.accessToken)
          commit('UPDATE_USER_INFO', response.data.userData, {root: true})

          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  fetchAccessToken () {
    return new Promise((resolve) => {
      jwt.refreshToken().then(response => { resolve(response) })
    })
  },
  deleteBearer () {
    delete axios.defaults.headers.common['Authorization']
    localStorage.clear()
  }
}
