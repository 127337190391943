/*=========================================================================================
  File Name: router.js
  Description: Routes for vue-router. Lazy loading is enabled.
  Object Structure:
                    path => router path
                    name => router name
                    component(lazy loading) => component to load
                    meta : {
                      rule => which user can have access (ACL)
                      breadcrumb => Add breadcrumb to specific page
                      pageTitle => Display title besides breadcrumb
                    }
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior () {
    return { x: 0, y: 0 }
  },
  routes: [
    // =============================================================================
    // Tribe LAYOUT ROUTES
    // =============================================================================
    {
      path: '',
      component: () => import('./layouts/tribe/Tribe.vue'),
      children: [
        // =============================================================================
        // Theme Routes
        // =============================================================================
        {
          path: '/',
          name: 'home',
          component: () => import('./views/Home.vue'),
          meta: {
            rule: 'guest',
            parent: 'find-a-partner'
          }
        },
        {
          path: '/partner/add',
          name: 'partner-add',
          component: () => import('./views/apps/partner/PartnerAdd.vue'),
          meta: {
            rule: 'user',
            parent: 'my-company'
          }
        },
        // =============================================================================
        // User Access
        // =============================================================================
        {
          path: '/profile/:userId',
          name: 'page-profile',
          component: () => import('@/views/apps/user/UserProfileEdit.vue'),
          meta: {
            rule: 'user'
          }
        },
        {
          path: '/admin/users/edit/:userId',
          name: 'user-edit',
          component: () => import('./views/apps/user/UserEdit.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: { name: 'home' } },
              { title: 'administration.user.breadcrumb.title', url: { name: 'user-list' }  },
              { title: 'administration.useredit.breadcrumb.title', active: true }
            ],
            pageTitle: 'administration.useredit.page.title',
            parent: 'Administration-users',
            rule: 'admin'
          }
        },
        {
          path: '/admin/users/add',
          name: 'user-add',
          component: () => import('./views/apps/user/UserAdd.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: { name: 'home' } },
              { title: 'administration.user.breadcrumb.title', url: { name: 'user-list' }  },
              { title: 'administration.useradd.breadcrumb.title', active: true }
            ],
            pageTitle: 'administration.useradd.page.title',
            parent: 'Administration-users',
            rule: 'admin'
          }
        },
        {
          path: '/admin/users',
          name: 'user-list',
          component: () => import('./views/apps/user/user-list/UserList.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: { name: 'home' } },
              { title: 'administration.user.breadcrumb.title', active: true  }
            ],
            pageTitle: 'administration.userlist.page.title',
            parent: 'Administration-users',
            rule: 'admin'
          }
        },
        {
          path: '/admin/users/groups',
          name: 'user-group-list',
          component: () => import('./views/apps/user-group/group-list/GroupList'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: { name: 'home' } },
              { title: 'administration.grouplist.page.title', active: true }
            ],
            pageTitle: 'administration.grouplist.page.title',
            parent: 'Administration-groups',
            rule: 'admin'
          }
        },
        {
          path: '/admin/users/groups/add',
          name: 'user-group-add',
          component: () => import('./views/apps/user-group/GroupEdit'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: { name: 'home' } },
              { title: 'administration.grouplist.page.title', url: { name: 'user-group-list' } },
              { title: 'administration.groupadd.breadcrumb.title', active: true }
            ],
            pageTitle: 'administration.groupadd.page.title',
            parent: 'Administration-groups',
            rule: 'admin'
          }
        },
        {
          path: '/admin/users/groups/:groupId',
          name: 'user-group-edit',
          component: () => import('./views/apps/user-group/GroupEdit'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: { name: 'home' } },
              { title: 'administration.grouplist.page.title', url: { name: 'user-group-list' } },
              { title: 'administration.groupedit.breadcrumb.title', active: true }
            ],
            pageTitle: 'administration.groupedit.page.title',
            parent: 'Administration-groups',
            rule: 'admin'
          }
        }
      ]
    },
    // =============================================================================
    // FULL PAGE LAYOUTS
    // =============================================================================
    {
      path: '',
      component: () => import('@/layouts/full-page/FullPage.vue'),
      children: [
        // =============================================================================
        // PAGES
        // =============================================================================
        {
          path: '/login',
          name: 'page-login',
          component: () => import('@/views/pages/UserAccess/Login.vue'),
          meta: {
            rule: 'guest'
          }
        },
        {
          path: '/logout',
          name: 'page-logout',
          component: () => import('@/views/pages/UserAccess/Logout.vue'),
          meta: {
            rule: 'user'
          }
        },
        {
          path: '/register',
          name: 'page-register',
          component: () => import('@/views/pages/UserAccess/Register.vue'),
          meta: {
            rule: 'guest'
          }
        },
        {
          path: '/forgot/password',
          name: 'page-forgot-password',
          component: () => import('@/views/pages/UserAccess/ForgotPassword.vue'),
          meta: {
            rule: 'guest'
          }
        },
        {
          path: '/reset/password',
          name: 'page-reset-password',
          component: () => import('@/views/pages/UserAccess/ResetPassword.vue'),
          meta: {
            rule: 'guest'
          }
        },
        {
          path: '/lock-screen',
          name: 'page-lock-screen',
          component: () => import('@/views/pages/LockScreen.vue'),
          meta: {
            rule: 'guest'
          }
        },
        {
          path: '/error-404',
          name: 'page-error-404',
          component: () => import('@/views/pages/Error/404.vue'),
          meta: {
            rule: 'guest'
          }
        },
        {
          path: '/error-500',
          name: 'page-error-500',
          component: () => import('@/views/pages/Error/500.vue'),
          meta: {
            rule: 'guest'
          }
        },
        {
          path: '/not-authorized',
          name: 'page-not-authorized',
          component: () => import('@/views/pages/UserAccess/NotAuthorized.vue'),
          meta: {
            rule: 'guest'
          }
        },
        {
          path: '/maintenance',
          name: 'page-maintenance',
          component: () => import('@/views/pages/Maintenance.vue'),
          meta: {
            rule: 'guest'
          }
        }
      ]
    },
    // Redirect to 404 page, if no match found
    {
      path: '*',
      redirect: '/error-404'
    }
  ]
})

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
